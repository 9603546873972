import React, { useState } from "react"

import TestScene from "../components/test-scene";

const TestPage = () => {

  const [isExploreActive, setIsExploreActive] = useState(false)

  return (
    <>
      <section>
        <div className="relative w-screen h-screen125 lg:h-screen125w overflow-hidden flex justify-center items-center">
          <div id="explore" className="relative flex justify-center items-center w-screen h-screen">
            <button onClick={() => {
              setIsExploreActive(!isExploreActive)
            }} className="text-4xl py-5 transform hover:scale-105 transition duration-300 ease-in-out group"><span className="tracking-tighter group-hover:tracking-widest">&lt;&lt;</span> Explore <span className="tracking-tighter group-hover:tracking-widest">&gt;&gt;</span></button>
          </div>
          <TestScene active={isExploreActive}></TestScene>
        </div>
      </section>
    </>
  )
}

export default TestPage