import { extend } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'

const GlobeSwirlMaterial = shaderMaterial(
  { time: 1, timeoffset: 0, offset: 1, resolution: [0,0] },
  `varying vec3 vUv;
  void main()
  {
    vUv = position;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }`,
  `uniform vec2 resolution;
  varying vec3 vUv;
  uniform float time;
  uniform float offset;
  uniform float timeoffset;
  
  const lowp float speed = 3.;
  const lowp vec3 cycle = vec3(.2523/3.,.9239745/3.,.43192/3.);
  const lowp vec3 col_base = vec3(0.4,0.4,1.);
  varying lowp vec3 col_base_cycle;
  varying lowp vec3 col_process;
  varying lowp vec3 col_blend;
  
  float blendOverlay(float base,float blend){
    return(blend==1.)?blend:min(base/(1.-blend),1.);
  }

  void main(){
    vec2 st=gl_FragCoord.xy/resolution.xy;
    st.x*=resolution.x/resolution.y;

    float t = timeoffset + time;
    
    vec3 col_base_cycle = vec3(
      sin(t*cycle.x)+.4,
      sin(t*cycle.y)+.4,
      sin(t*cycle.z)+1.);

    vec3 p = offset + 0.075 * vUv;
    float m = t/speed;
    
    for(int i=1;i<4;i++)
    {
      vec3 newp=p;
      newp.x+=3./float(i)*sin(float(i)*p.y+m);
      newp.y+=1./float(i)*cos(float(i)*p.x+m);
      p=newp;
    }

    vec3 col_process = vec3(
      sin(p.x)+.4,
      sin(p.y)+.4,
      sin(p.x+p.y));
    
    vec3 col_blend=vec3(
      blendOverlay(col_base_cycle.r,col_process.r),
      blendOverlay(col_base_cycle.g,col_process.g),
      blendOverlay(col_base_cycle.b,col_process.b));

      float o = smoothstep(0.0, 1.0, 1.0 - ((vUv.z) * 0.06)) * 0.5;
  
      gl_FragColor = vec4(col_blend, 1.0 - (vUv.z * vUv.z * 0.001));
  }`,
  (m) => {
    m.transparent = true
  }
)

extend({ GlobeSwirlMaterial })