import React, { useRef } from "react"
import { Canvas, useFrame } from '@react-three/fiber'
import { useSpring, animated, config } from '@react-spring/three'
import * as THREE from "three"
import { PerspectiveCamera } from '@react-three/drei'
import "../materials/globeSwirlMaterial"


const Globe = (props) => {

  const group = useRef();
  const meshFront = useRef();
  const meshBack = useRef();
  const globeFrontMat = useRef();
  const globeBackMat = useRef();

  useFrame((state, delta) => {
    group.current.rotation.x += delta * 0.0053
    group.current.rotation.y += delta * 0.0203
    globeFrontMat.current.time += delta
    globeBackMat.current.time = globeFrontMat.current.time
  }
  )

  return (
    <animated.group
      {...props}
      ref={group}>
      <mesh
        ref={meshFront}
        renderOrder={2}>
        <sphereGeometry args={[25, 60, 60]} />
        <globeSwirlMaterial
          ref={globeFrontMat}
          side={THREE.FrontSide} />
      </mesh>
      <mesh
        ref={meshBack}
        renderOrder={1}>
        <sphereGeometry args={[25, 60, 60]} />
        <globeSwirlMaterial
          ref={globeBackMat}
          side={THREE.BackSide} />
      </mesh>
    </animated.group>

  )

}

const TestScene = (props) => {

  const {active} = props

  const AnimatedPerspectiveCamera = animated(PerspectiveCamera)

  const {pos} = useSpring({
    pos: active ? [0,0,1500] : [0,0,100],
    config: active ? { mass: 1, tension: 80, friction: 80 } : config.slow})

  return (
    <div style={{
      position: `absolute`,
      top: 0,
      width: `100%`, height: `100%`,
      zIndex: -10,
    }}>
      <Canvas>
        <AnimatedPerspectiveCamera 
        makeDefault 
        fov={30}
        near={0.1} 
        far={2000} 
        position={pos}
         />
        <Globe />
      </Canvas>
    </div>
  )

}

export default TestScene